.App {
  text-align: center;
}

*{
  color: #fff;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: url('./img/DESKTOP.webp') ;
  background-size: cover;
  overflow-x: hidden;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter-Regular.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'Epilogue';
  src: url('./fonts/Epilogue-Bold.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'Rajdhani';
  src: url('./fonts/Rajdhani-Bold.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'EpilogueSemi';
  src: url('./fonts/Epilogue-Medium.ttf');
  font-display: swap;
}

.yellow {
  color: #E9CA1A;
}

.semi{
  font-family: 'EpilogueSemi', Verdana, Geneva, Tahoma, sans-serif;
}

.carousel {
  display: flex !important;
  flex-wrap: wrap !important;
  align-content: center;
  border-radius: 100px !important;
}

ul {
  padding: 0 !important;
  margin: 0 !important;
}

ul, p {
  font-family: 'Inter', Verdana, Geneva, Tahoma, sans-serif;
  font-size: 1vw !important;
}

h1, h3{
  font-family: 'Epilogue', Verdana, Geneva, Tahoma, sans-serif;
  font-size: 1.5vw !important;
}

@media (max-width: 1000px) {
  #root {
    background: url('./img/MOBILE\ -\ HOME.webp');
    background-size: cover;
  }

  ul, p {
    list-style: none;
    font-size: 3vw !important;
  }
  
  h1,h2,h3{
    font-size: 4vw !important;
  }
}