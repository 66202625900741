.Nav {
    background-color: #3b2e447a;
    font-family: 'Rajdhani';
}

.Rad {
    font-family: 'Rajdhani';
}

.Nav a, .Nav a:visited, .Nav a:active {
    text-decoration: none;
    color: white ;
    text-transform: uppercase;
}

.nav-link:hover {
    color: grey !important
}